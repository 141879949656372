import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Layout from '../components/layout'

import TimeLineComponent from '../components/timeline'
import Hero from '../components/hero'


class Story extends React.Component {
    render() {
      const stories = get(this, 'props.data.allContentfulStoryOfMakersInc.nodes')
      const [siteInfo] = get(this, 'props.data.allContentfulSiteInfo.nodes')

    return (
      <Layout location={this.props.location}>
        <Hero
        image={siteInfo.frontPageImage.gatsbyImageData}
        title={siteInfo.title}
        content={siteInfo.description}
        />
        <TimeLineComponent stories={stories}/>
      </Layout>
    )
    }
}

export default Story

export const pageQuery = graphql`
query StoryQuery {
    allContentfulStoryOfMakersInc(sort: { fields: [actualDate], order: ASC }) {
      nodes {
        date
        description {
          raw
        }
        actualDate(formatString: "MMMM Do, YYYY")
        image {
          gatsbyImageData
          file {
            url
          }
        }
      }
    }, 
    allContentfulSiteInfo(
      filter: { contentful_id: { eq: "3MGSRPe1ACEH1pycfknfh1" } }
    ) {
      nodes {
        title
        description
        frontPageImage {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            width: 1180
            quality: 100
          )
        }
      }
    }
  }  
`