import React from 'react'
import {
  Timeline,
  Events,
  ImageEvent,
  themes, 
  createTheme
} from '@merc/react-timeline';

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { renderRichText } from "gatsby-source-contentful/rich-text"


const Bold = ({ children }) => <span className="bold">{children}</span>
const Text = ({ children }) => <p className="align-center">{children}</p>

const options = {
    renderMark: {
        [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
        [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
        [INLINES.HYPERLINK]: ({ data }, children) => (
          <a
            href={data.uri}
          >{children}</a>
        )
    },
}

const customTheme = createTheme(themes.default, {
    timeline: {
        backgroundColor: "inherit",
        fontFamily: 'Catamaran',
      },
    timelineTrack: {
        left: "50%",
        width: "2px",
        height: "100%",
        backgroundColor: "#e68f72",
      },
    marker: {
        backgroundColor: "#ffffff",
        border: "2px solid #e68f72",
        borderRadius: "50%",
        width: "20px",
        height: "20px",
        zIndex: "100"
      },
    //   card: {
    //     borderRadius: 4px,
    //     backgroundColor: #fff,
    //     color: #333,
    //     padding: 10px,
    //     boxShadow: 0 4px 6px 0 hsla(0, 0%, 0%, 0.2),
    //     width: 100%,
    //     maxWidth: 560px,
    //     a: {
    //       color: #EC24B5
    //     }
    //   },
    button: {
        fontSize: "26px",
        backgroundColor: "#e68f72",
        borderRadius: "4px",
        padding: "6px",
        color: "#ffffff",
        margin: "10px 5px 5px 0",
        border: "none",
        cursor: "pointer"
      },
    //   urlButton: {
    //     fontSize: 14px,
    //     backgroundColor: rebeccapurple,
    //     borderRadius: 4px,
    //     padding: 6px,
    //     margin: 10px 5px 5px 0,
    //     border: none,
    //     color: #fff
    //   },
    date: {
        backgroundColor: "#e68f72",
        padding: "10px",
        color: "#ffffff",
        borderRadius: "4px",
        fontWeight: "500",
        fontSize: "18px",
      },
    imageAtom: {
        objectFit: "cover",
        overflow: "hidden",
        width: "100%",
        maxHeight: "1000px"
      },
    //   imageCredit: {
    //     marginTop: 10px,
    //     fontSize: 0.85rem
    //   },
      imageText: {
        fontSize: "20px"
      },
    //   youTubeText: {
    //     marginBottom: 10px,
    //     fontSize: 1rem
    //   },
    //   events: {
    //     padding: 10px
    //   },
    //   event: {
    //     marginBottom: 20px
    //   },
    //   textAtom: {}
  });
  


const TimeLineComponent = ({ stories }) => (
    <Timeline theme={customTheme}>
        <Events>
            <ImageEvent
            date={stories[0].date}
            text={renderRichText(stories[0].description,options)[0].props.children[0]}
            src={stories[0].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[1].date}
            text={renderRichText(stories[1].description,options)[0].props.children[0]}
            src={stories[1].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[2].date}
            text={renderRichText(stories[2].description,options)[0].props.children[0]}
            src={stories[2].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[3].date}
            text={renderRichText(stories[3].description,options)[0].props.children[0]}
            src={stories[3].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[4].date}
            text={renderRichText(stories[4].description,options)[0].props.children[0]}
            src={stories[4].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[5].date}
            text={renderRichText(stories[5].description,options)[0].props.children[0]}
            src={stories[5].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[6].date}
            text={renderRichText(stories[6].description,options)[0].props.children[0]}
            src={stories[6].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[7].date}
            text={renderRichText(stories[7].description,options)[0].props.children[0]}
            src={stories[7].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[8].date}
            text={renderRichText(stories[8].description,options)[0].props.children[0]}
            src={stories[8].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[9].date}
            text={renderRichText(stories[9].description,options)[0].props.children[0]}
            src={stories[9].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[10].date}
            text={renderRichText(stories[10].description,options)[0].props.children[0]}
            src={stories[10].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[11].date}
            // text={renderRichText(stories[11].description,options)[0].props.children[0].concat(renderRichText(stories[11].description,options)[0].props.children[1].props.children[0])}
            text={"Een fotoshoot met mijn favoriete fotograaf, Martine van [Shoot & Write](https://www.shootandwrite.nl)."}
            src={stories[11].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[12].date}
            text={renderRichText(stories[12].description,options)[0].props.children[0]}
            src={stories[12].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

            <ImageEvent
            date={stories[13].date}
            text={renderRichText(stories[13].description,options)[0].props.children[0]}
            src={stories[13].image.file.url}
            alt=""
            credit=""
            >
            </ImageEvent>

        </Events>
    </Timeline>
);

export default TimeLineComponent